<template>
  <div>
    <main class="s-main-bg">
      <Navbar/>
      <div class="s-ht-100-vh s-keep-view">
        <div class="s-main-container ">
          <div class="s-fullcenter-flex s-ptb-60">
            <div class="s-card-content s-bg-white s-mt-30 s-p-30">
              <div class="s-wd-400">
                <img class="s-fullWidth" :src="require('@/assets/page_notfound.svg')"/>
              </div>
              <div class="s-text-center ">
                <h5>Not Found</h5>
                <p>Halaman tidak ditemukan</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </main>
  </div>
</template>
<script>
import Navbar from "@/components/main/navbar";
import Footer from "@/components/main/footer";
export default {
  name: "Home",
  components:{
    Navbar, 
    Footer
  }
};
</script>
